export default class Lightbox {

    constructor( element, options ) {

        this.$images = $(element);
        this.$images.each((i,e) => {
            e.dataset.index = i;
        });

        this.total = this.$images.length;
        if ( this.total < 1 ) return false;

        this.build_lightbox();
        this.defaults();
        this.events();

    }

    build_lightbox() {
        let lightbox_html = '';
        lightbox_html += '<div class="lightbox" data-index="0">';
        lightbox_html +=    '<div class="lightbox__loader"><div class="loader"></div></div>';
        lightbox_html +=    '<svg class="lightbox__close"><use xlink:href="#bmv-close"></use></svg>';
        lightbox_html +=    '<svg class="lightbox__nav lightbox__left"><use xlink:href="#bmv-left"></use></svg>';
        lightbox_html +=    '<svg class="lightbox__nav lightbox__right"><use xlink:href="#bmv-right"></use></svg>';
        lightbox_html +=    '<figure class="lightbox__figure">';
        lightbox_html +=        '<figcaption class="lightbox__figcaption">';
        lightbox_html +=            '<h3 class="lightbox__title"></h3>';
        lightbox_html +=            '<div class="lightbox__desc"></div>';
        lightbox_html +=        '</figcaption>';
        lightbox_html +=        '<ul class="lightbox__switch">';
        lightbox_html +=            '<li class="lightbox__switch__option" data-name="advert" data-active="true">Advert</li>';
        lightbox_html +=            '<li class="lightbox__switch__option" data-name="newspaper">Newspaper</li>';
        lightbox_html +=        '</ul>';
        lightbox_html +=        '<div class="lightbox__wrap">';
        lightbox_html +=            '<span class="lightbox__image"></span>';
        lightbox_html +=            '<img class="lightbox__image__print" src=""/>';
        lightbox_html +=        '</div>';
        lightbox_html +=        '<ul class="lightbox__actions">';
        lightbox_html +=            '<li class="lightbox__print">';
        lightbox_html +=                '<svg><use xlink:href="#bmv-print"></use></svg>';
        lightbox_html +=                '<span>Print</span>';
        lightbox_html +=            '</li>';
        lightbox_html +=        '</ul>';
        lightbox_html +=    '</figure>';
        lightbox_html +=    '<div class="lightbox__bg"></div>';
        lightbox_html += '</div>';
        $(lightbox_html).insertBefore('footer.footer');
    }

    defaults() {
        this.default_image = bmv.default_image_src;
        // Lightbox
        this.$lightbox = $('.lightbox');
        this.$lightbox_bg = $('.lightbox__bg');
        this.$close = this.$lightbox.find('.lightbox__close');
        this.$print = this.$lightbox.find('.lightbox__print');
        this.$img_print = this.$lightbox.find('.lightbox__image__print');
        // Img data
        this.img = null;
        this.$image = this.$lightbox.find('.lightbox__image');
        this.$title = this.$lightbox.find('.lightbox__title');
        this.$caption = this.$lightbox.find('.lightbox__desc');
        // Nav buttons
        this.$right = this.$lightbox.find('.lightbox__right');
        this.$left = this.$lightbox.find('.lightbox__left');
        this.$nav = this.$lightbox.find('.lightbox__nav');
        // Switches
        this.$switches = this.$lightbox.find('.lightbox__switch__option');
    }

    events() {

        // Close button click
        this.$close.off('click').on('click', () => {
            this.close();
        });

        this.$print.off('click').on('click', () => {
            window.print();
        });

        // White space click
        this.$lightbox_bg.off('click').on('click', () => {
            this.close();
        });

        // Gallery image clicked
        this.$images.off('click').on('click', ( e ) => {
            e.preventDefault();

            this.clear_lightbox();

            let $img = $(e.currentTarget);
            let img = {
                'index'     : parseInt( $img.attr('data-index') ),
                'src'       : $img.attr('href'),
                'title'     : $img.attr('title'),
                'caption'   : $img.attr('data-caption'),
                'edition'   : $img.attr('data-edition')
            }

            this.img = img;

            $('html,body').css('overflow','hidden');
            this.$lightbox.attr('data-status', 'loading');
            if ( this.total > 1 ) this.$nav.attr('data-status','inactive');
            
            let temp_img = new Image();
            temp_img.src = img.src;
            temp_img.onload = () => {
                if ( this.total > 1 ) this.$nav.attr('data-status','');
                this.set_nav( img.index );
                this.$image.css('background-image', 'url(' + img.src + ')');
                this.$img_print.attr('src', img.src);
                this.$title.html(img.title);
                this.$caption.html(img.caption);
                this.$lightbox.attr('data-status', 'active');
                this.$lightbox.attr('data-index', img.index);
            };
            temp_img.onerror = () => {
                if ( this.total > 1 ) this.$nav.attr('data-status','');
                this.set_nav( img.index );
                this.$image.css('background-image', 'url(' + this.default_image + ')');
                this.$img_print.attr('src', this.default_image);
                this.$title.html(img.title);
                this.$caption.html(img.caption);
                this.$lightbox.attr('data-status', 'active');
                this.$lightbox.attr('data-index', img.index);
            };

            this.$switches.off('click').on('click', (e) => {
                if ( e.currentTarget.dataset.active === 'true' ) return false;
                this.$switches.attr('data-active', 'false');
                e.currentTarget.dataset.active = 'true';

                let name = e.currentTarget.dataset.name;

                this.$lightbox.attr('data-status', 'loading');
                if ( this.total > 1 ) this.$nav.attr('data-status','inactive');
                let temp_img = new Image();
                let new_img_src = ( name === 'newspaper' ) ? this.img.edition : this.img.src;

                temp_img.src = new_img_src;
                temp_img.onload = () => {
                    if ( this.total > 1 ) this.$nav.attr('data-status','');
                    this.$image.css('background-image', 'url(' + new_img_src + ')');
                    this.$img_print.attr('src', new_img_src);
                    this.$lightbox.attr('data-status', 'active');
                };
                temp_img.onerror = () => {
                    if ( this.total > 1 ) this.$nav.attr('data-status','');
                    this.$image.css('background-image', 'url(' + this.default_image + ')');
                    this.$img_print.attr('src', this.default_image);
                    this.$lightbox.attr('data-status', 'active');
                };

            });

        });

        if ( this.total > 1 ) {

            this.$left.off('click').on('click', () => {
                let current_index = parseInt( this.$lightbox.attr('data-index') );
                this.$images[ (current_index - 1) ].click();
            });
        
            this.$right.off('click').on('click', () => {
                let current_index = parseInt( this.$lightbox.attr('data-index') );
                this.$images[ (current_index + 1) ].click();
            });

        } else {
            this.$nav.attr('data-status', 'hidden');
        }

    }

    close() {
        $('html,body').css('overflow','visible');
        this.$lightbox.attr('data-status', 'inactive');
        this.$lightbox.attr('data-index', '0');
        this.$image.css('background-image', 'none');
        this.$title.html('');
        this.$caption.html('');
        this.img = null;
        this.set_nav(0);
    }

    set_nav( index ) {
        if ( this.total < 1 ) return false;
        if ( ( index + 1 ) >= this.total ) this.$right.attr('data-status','inactive');
        else if ( index < 1 ) this.$left.attr('data-status','inactive');
        else this.$nav.attr('data-status','');
    }

    clear_lightbox() {
        this.$image.css('background-image', 'none');
        this.img = null;
        this.$title.html('');
        this.$caption.html('');
        this.$switches.attr('data-active', 'false');
        this.$switches.first().attr('data-active', 'true');
    }

}
