
$('a[href^="#"]').off('click').on('click', (e) => {
    e.preventDefault();

    let href = e.currentTarget.getAttribute('href');
    if ( ! $(href).length ) return false;

    // Scroll to element
    $('html, body').animate({ scrollTop: $(href).offset().top }, 150);

    if ( href !== '#contact' ) return true;

    // Blur the first input field of the form
    $(href).find('.form__input').first().focus();

});


$('.initsearch').off('click').on('click', (e) => {
    e.preventDefault();
    let $e = $(e.currentTarget);
    let href = $e.find('.nav__link').attr('href');
    if ( ! href) window.location = href;
    if ( $('.search__input').length < 1 ) window.location = href;
    $('.search__input').first().focus();
});
