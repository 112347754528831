
import loadImage from 'image-promise';

/**
 * Search module
 */
export default class AnimateResults {
    
    constructor() {
        $('.result').each( ( i, e ) => { 
            let delay = i > 5 ? 1000 : ( i * 200 );
            setTimeout( () => {
                e.dataset.status = 'loading'
                let images = $(e).find('img');
                loadImage( images ).then( ( all ) => {
                    e.dataset.status = 'display';
                }).catch( ( err ) => {
                    for ( let img_err of err.errored ) {
                        img_err.src = bmv.dist_url + 'img/placeholder.jpg';
                    }
                    e.dataset.status = 'display';
                });
            }, delay );
        });
    }

}
    
