
export default class ListFilter {
    
    constructor() {
        if ( $('.biglist__filters').length < 1 ) return false;

        this.$filters = $('.biglist__filters').find('li');
        this.$reset = $('.biglist__hr > span');


        $('.biglist__section').each( (i,e) => {
            $(e).css('min-height', $(e).height() + 'px');
        });

        this.$filters.off('click').on('click', (e) => {

            this.$filters.attr('data-active', 'false');

            let $section = $(e.currentTarget).closest('.biglist__section');
            $section.find('.biglist__hr > span').attr('data-active', 'false');

            e.currentTarget.dataset.active = 'true';

            let filter_by = e.currentTarget.dataset.name;
            $section.find('.biglist__badge').each( (i,e) => {
                if ( e.dataset.name !== filter_by ) {
                    e.dataset.hidden = 'true';
                } else {
                    e.dataset.hidden = 'false';
                }
            });
        });

        $('.biglist__hr > span').off('click').on('click', (e) => {
            this.$filters.attr('data-active', 'false');
            e.currentTarget.dataset.active = 'true';
            $(e.currentTarget).closest('.biglist__section').find('.biglist__badge').each( (i,e) => {
                e.dataset.hidden = 'false';
            });
        });
    }

}
    
