
/**
 * Babel polyfill
 */
import 'babel-polyfill';

// Misc functions
import './misc.js';

// Search 
import AnimateResults from './modules/animate-results.js';
import Autocomplete from './modules/search-autocomplete.js';
import Lightbox from './modules/lightbox.js';
import ListFilter from './modules/list-filter.js';

new AnimateResults();
new Autocomplete();
var Gallery = new Lightbox('.gallery__item__link', {});
new ListFilter();