
/**
 * Search input autocomplete
 */
export default class Autocomplete {
    
    constructor() {
        this.$input = $('.search__input.search-autocomplete');
        if ( this.$input.length < 1 ) return false;

        this.$search = $('.search');
        this.$search_autocomplete = $('.search__autocomplete');
        this.search_delay = 500;

        this.default();
        this.events();
    }

    default() {
        this.term = '';
        this.action = 'search_tags';
        this.results = null;
        this.searching = false;
        this.typingTimer = null;
        this.$search_autocomplete.html('');
    }

    events() {
        // Set timer and start searching
        this.$input.off('keyup').on('keyup', () => {
            clearTimeout( this.typingTimer );
            this.typingTimer = setTimeout( () => {
                this.$search_autocomplete.html('');

                this.term = this.$input.val().toLowerCase();

                // End the search if empty term
                if ( !/\S/.test( this.term ) ) {
                    this.$search.attr('data-status', '');
                    return false;
                }

                this.search();
            }, this.search_delay);
        });

        // Clear timer as user pressed another key
        this.$input.off('keydown').on('keydown', () => {
            clearTimeout( this.typingTimer );
        });

        // Hide on blur
        $(document).off('click').on('click', (e) => {
            if ( this.$search.attr('data-status') === 'autocomplete' ) {
                if ( ! $(e.target).closest('.search-autocomplete').length ) {
                    this.$search.attr('data-status', '');
                }
            }
        });

        // Show on focus
        this.$input.off('focus').on('focus', () => {
            this.$search.attr('data-status', 'autocomplete');
        });

    }

    search() {

        this.start_search();
        this.action = 'search_' + $('input[name="dir_type"]').val();

        $.when( this.go() ).then( () => {
            if ( this.results.length > 0 ) {
                this.display_results();
            }
            this.end_search();
        });

    }

    go() {
        return $.ajax({
            context : this,
            url     : bmv.ajax_url,
            data    : {
                action : this.action,
                term : this.term,
            },
            success : ( result ) => { this.results = result; },
            error   : ( xhr, status, error ) => {
                this.results = null;
                console.error( [ status, error, xhr.responseText ] );
            }
        });
    }

    start_search() {
        this.searching = true;
        this.$search.attr('data-status', 'loading');
    }

    end_search() {
        this.searching = false;
        this.complete_events();
        this.$search.attr('data-status', 'autocomplete');
    }

    display_results() {
        for ( let result of this.results ) {
            this.$search_autocomplete.append('<li data-val="'+result+'"><span>'+ result +'</span></li>');
        }
    }

    complete_events() {
        this.$search_autocomplete.find('li').off('click').on('click', (e) => {
            this.$input.val( $(e.currentTarget).attr('data-val') );
            this.$search.attr('data-status', '');
            this.$search.find('form').submit();
        });
    }

}
    
